@use "sass:color";
@import "@patternfly/patternfly/sass-utilities/scss-variables";
$ncr-inactive-icon-opacity: 0.3;

$ncr--estimate--text-color: #{$pf-global--Color--400};
$ncr--estimate_no-estimate--text-color: #{$pf-global--Color--light-300};

$ncr--money_income--text-color: #{$pf-global--success-color--100};
$ncr--money_income--text-color_hover: color.adjust($pf-global--success-color--100, $lightness: -25%, $saturation: 30%);
$ncr--money_expense--text-color:  #{$pf-global--danger-color--200};
$ncr--money_expense--text-color_hover: darken($pf-global--danger-color--200, 30%);
