@import "variables";

:root {
  --ncr-grid-tool--Size: 28px;
}

/* report grid */
/* right align monetary (headers and cells) + all inner table cells */
.ncr-report-grid>thead th {
  text-align: right;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 12px 8px 8px 8px;
}

.ncr-report-grid td.ncr-report-data-col-heading {
  min-width: 15em;
}

.ncr-grid .ncr-grid-heading {
  display: flex;
  align-items: center;
  height: var(--ncr-grid-tool--Size);
}

.ncr-grid .ncr-grid-row-expander {
  margin-right: 4px;
}

.ncr-grid .ncr-grid-tools {
  display: flex;
  margin-left: 8px;
}

.ncr-grid .ncr-grid-tools:not(:first-child) {
  margin-left: 16px;
}

.ncr-grid .ncr-grid-tool {
  cursor: pointer;
  opacity: 0;
  display: inline-block;
  height: var(--ncr-grid-tool--Size);
  width: var(--ncr-grid-tool--Size);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(32, 33, 36, 0.05);
  border-radius: 50%;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.ncr-grid .ncr-grid-tool:not(:last-child) {
  margin-right: 4px;
}

.ncr-grid td:not(:has(.ncr-grid)):hover .ncr-grid-tool {
  visibility: visible;
  opacity: 0.4;
  transition: opacity 0.75s ease !important;
}

.ncr-grid td:not(:has(.ncr-grid)) .ncr-grid-tool:hover {
  opacity: 1;
  transition: opacity 0.25s ease !important;
}

.ncr-report-grid.ncr-c-report-header {
  background-color: var(--pf-global--active-color--100);
  color: var(--pf-global--Color--light-100);
}

%column-styles {
  background-color: var(--pf-global--BackgroundColor--200) !important;
}

@for $i from 2 to 16 {
  table.ncr-report-grid:has(tr.ncr-m-data > *:nth-child(#{$i}):hover) > tbody>tr.ncr-m-data > *:nth-child(#{$i}) {
    @extend %column-styles;
  }
}

.ncr-report-grid>tbody>tr.ncr-m-data {
  &:hover>td{
    background-color: var(--pf-global--BackgroundColor--200);
  }
}

.ncr-report-grid.ncr-m-extended>thead>tr>th {
  color: var(--pf-global--disabled-color--200);
  transition: all 0.75s ease !important;
}

.ncr-report-grid.ncr-m-extended>thead>tr>th.ncr-m-extended {
  color: revert;
  transition: all 0.25s ease !important;
}

.ncr-report-grid.ncr-m-extended>tbody>tr.ncr-m-data,
.ncr-report-grid.ncr-m-extended>tbody>tr.ncr-m-header,
.ncr-report-grid.ncr-m-extended>tbody>tr.ncr-m-data.ncr-m-extended>td,
.ncr-report-grid.ncr-m-extended>tbody>tr.ncr-m-data.ncr-m-extended>td.ncr-m-extended .ncr-extendable {
  opacity: 0.5;
  filter: saturate(0.75);
  transition: all 0.75s ease !important;
}

.ncr-report-grid.ncr-m-extended>tbody>tr.ncr-m-data.ncr-m-extended,
.ncr-report-grid.ncr-m-extended>tbody>tr.ncr-m-header.ncr-m-extended,
.ncr-report-grid.ncr-m-extended>tbody>tr.ncr-m-data.ncr-m-extended>td.ncr-report-data-col-heading,
.ncr-report-grid.ncr-m-extended>tbody>tr.ncr-m-data.ncr-m-extended>td.ncr-m-extended,
.ncr-report-grid.ncr-m-extended>tbody>tr.ncr-m-data.ncr-m-extended>td.ncr-m-extended .ncr-extendable.ncr-m-extended {
  filter: none;
  opacity: 1;
  transition: opacity, border-bottom-color 0.25s ease !important;
}

.ncr-report-grid.ncr-m-extended>tbody>tr.ncr-m-data.ncr-m-extended>td.ncr-report-data-col-heading {
  font-weight: bold;
}

.ncr-report-grid.ncr-m-extended>tbody>tr.ncr-m-data.ncr-m-extended>td.ncr-m-extended {
  border-bottom: 3px solid var(--pf-global--primary-color--100);
  padding-bottom: 3px;
}

.ncr-report-grid.ncr-m-extended>tbody>tr.ncr-m-data.ncr-m-extended>td.ncr-m-extended .ncr-extendable.ncr-m-extended {
  text-decoration: underline;
}

/* actual and estimated grid */
.ncr-report-grid table.ncr-report-a-and-e {
  width: 100%;
}

.ncr-report-grid table.ncr-report-a-and-e tr {
  border: none;
}

.ncr-report-grid table.ncr-report-a-and-e td {
  padding: 0;
  font-size: 0.9em;
  text-align: right;
}

.ncr-report-a-and-e td.ncr-report-estimate {
  color: $ncr--estimate--text-color;
}

.ncr-report-a-and-e td.ncr-report-estimate.ncr-report-estimates__no-estimate {
  color: $ncr--estimate_no-estimate--text-color;
}
